import React, { useState, useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import CheckoutForm from "./components/order/Checkout";
import Gallery from "./components/gallery/Gallery";
import ItemPage from "./components/gallery/ItemPage";
import LoginForm from "./components/login/LoginForm";
import NotFound from "./components/notfound/NotFound";
import OrderForm from "./components/order/Order";

import Badge from "@material-ui/core/Badge";
import CircularProgress from "@material-ui/core/CircularProgress";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import { createTheme } from "@material-ui/core/styles";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import "./App.css";
import logo from "./logo.png";

const stripePromise = loadStripe("pk_live_80EF3UNJD7lLyz9CJxVMeRQG00nA5a1TNk");

const App = () => {
  const [session, setSession] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const incNumItems = () => {
    setSession({
      ...session,
      numItems: session.numItems + 1,
    });
  };
  const wipeBadge = () => {
    setSession({
      ...session,
      numItems: 0,
    });
  };

  useEffect(() => {
    if (!session) {
      const fetchIdentity = async () => {
        setIsLoading(true);
        await fetch("/api/auth/identity")
          .then((res) => res.json())
          .then((data) => setSession(data));
        setIsLoading(false);
      };

      fetchIdentity();
    }
  }, [session]);

  return (
    <BrowserRouter>
      <img alt="logo" className="logo" src={logo} />
      <ThemeProvider
        theme={createTheme({
          spacing: 4,
          typography: {
            fontFamily: ['"Avenir Next"'].join(","),
            h1: {
              letterSpacing: "12em",
            },
            h2: {
              fontWeight: "700",
              letterSpacing: "12em",
            },
            h3: {
              fontSize: "26px",
              fontWeight: "400",
            },
            button: {
              fontWeight: "700",
              letterSpacing: "2px",
            },
          },
          palette: {
            action: {
              active: "#a35c6e",
            },
            background: {
              paper: "#a35c6e",
            },
            error: {
              main: "#680e11",
            },
            text: {
              primary: "#ffffff",
            },
            primary: {
              main: "#e9a6b8",
            },
            secondary: {
              main: "#680e11",
            },
          },
        })}
      >
        {
          <div className="bannerContainer">
            <div className="bannerText">
              Nutcracker 2024 photos coming soon! <a href="https://docs.google.com/forms/d/e/1FAIpQLSdxsKNh8UPkkCve8NfepV0Dgm0_LTcTN3syfRzuv27RKhigSw/viewform?usp=sharing">Click here</a> to get notified.
            </div>
            <br />
            <div className="bannerText">
              Buy 1-4 image for $12 each, or 5+ images for $9.50 each
            </div>
          </div>
        }
        {isLoading ? (
          <CircularProgress />
        ) : (
          <React.Fragment>
            <div className="nav">
              <a
                className="navElement"
                onClick={() => (window.location = "/gallery")}
              >
                GALLERY
              </a>
              {session && session.userId && (
                <Badge
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={session.numItems}
                  className="navElement"
                  color="primary"
                  max={100}
                  onClick={() => (window.location = "/order")}
                >
                  CART
                </Badge>
              )}
              {!(session && session.userId) ? (
                <a
                  className="navElement"
                  onClick={() => (window.location = "/login")}
                >
                  LOGIN
                </a>
              ) : (
                <a
                  className="navElement"
                  onClick={async () => {
                    setIsLoading(true);
                    await fetch("/api/auth/logout", {
                      method: "POST",
                    })
                      .then((res) => res.json())
                      .then(() => setSession(null));
                    setIsLoading(false);
                  }}
                >
                  LOG OUT
                </a>
              )}
            </div>
            <div className="page">
              <Routes>
                <Route
                  exact
                  path="/"
                  element={<Navigate replace to={{ pathname: "/gallery" }} />}
                />
                <Route element={<LoginForm />} path="/login" />
                <Route
                  path="/gallery"
                  element={
                    session && session.userId ? (
                      <Gallery />
                    ) : (
                      <Navigate replace to={{ pathname: "/login" }} />
                    )
                  }
                />
                <Route
                  path="/gallery/:galleryId"
                  element={
                    session && session.userId ? (
                      <Gallery />
                    ) : (
                      <Navigate replace to={{ pathname: "/login" }} />
                    )
                  }
                />
                <Route
                  path="/item/:itemId"
                  element={
                    session && session.userId ? (
                      <ItemPage incNumItems={incNumItems} />
                    ) : (
                      <Navigate replace to={{ pathname: "/login" }} />
                    )
                  }
                />
                <Route
                  path="/order"
                  element={
                    session && session.userId ? (
                      <OrderForm />
                    ) : (
                      <Navigate replace to={{ pathname: "/login" }} />
                    )
                  }
                />
                <Route
                  path="/checkout"
                  element={
                    session && session.userId ? (
                      <Elements stripe={stripePromise}>
                        <CheckoutForm wipeBadge={wipeBadge} />
                      </Elements>
                    ) : (
                      <Navigate replace to={{ pathname: "/login" }} />
                    )
                  }
                />
                <Route component={NotFound} />
              </Routes>
            </div>
          </React.Fragment>
        )}
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
